@charset "UTF-8";

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Theme(s)
@import 'node_modules/@engineering11/ui-theme-admin/stylesheets/theme';

// E11 Component Styles
@import 'node_modules/@engineering11/ui-lib/assets/stylesheets/components/base.scss';

// Material Icon sizes
@import '../../shared-lib/src/assets/stylesheets/libs/material-icon-sizes';

// Application layout styles
header {
  position: fixed;
  width: 100%;
  top: 0;
}

.layout {
  display: flex;
  min-height: calc(100vh - 160px); // full screen minus header and footer height
  margin-top: 112px; // header height / header is 80 plus 2rem, 32px
  .layout-left {
    width: 20%;
    max-width: 300px;
  }
  .layout-content {
  }
}
